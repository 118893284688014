import fp from 'lodash/fp';
import * as dfp from 'date-fns/fp';
import * as lib from '../lib';

const formatDateTime = dfp.format('p O P');

export default class FormController {
    constructor(formId) {
        this._formId = formId;
        this._log = new lib.NonceLog(`FormController(${formId})`);
        this._log.log('new');
        this._pageControllers = {};
        this.current = {pageNumber: 0, element: null};
        this._ordersValue = undefined;
        // updated each render
        this.form = null;
        this.options = {};
    }

    get signatureValue() {
        const {user, signatureBlob} = this.options;
        const url = fp.get(
            'relationships.content.links.related',
            signatureBlob,
        );
        const name = fp.get('attributes.label', user);
        const timestamp = formatDateTime(new Date());
        const result = {name, url, timestamp};
        this._log.log('signatureValue', {
            signatureBlob,
            result,
        });
        return JSON.stringify(result);
    }

    get ordersValue() {
        return fp.isUndefined(this._ordersValue)
            ? this.options.ordersValue
            : this._ordersValue;
    }

    countIncomplete = () => {
        const numIncomplete = fp.sum(
            fp.map(pc => pc.numIncomplete, this._pageControllers),
        );
        this.options.setNumIncomplete?.(numIncomplete);
    };

    handleSavePage = (blob, fields, page) => {
        const {onSavePage} = this.options;
        if (onSavePage) {
            return onSavePage(blob, fields, page);
        }
    };

    handleEditOrders = async () => {
        const {onEditOrders} = this.options;
        if (onEditOrders) {
            const newValue = await onEditOrders(this.ordersValue);
            if (!fp.isUndefined(newValue)) {
                this._ordersValue = newValue;
            }
        }
        return this._ordersValue;
    };

    save = async () => {
        this._log.log('save begin');
        await Promise.all(fp.map(pc => pc.saveCanvas(), this._pageControllers));
        const {onSaveOrders} = this.options;
        if (
            onSaveOrders &&
            !fp.isUndefined(this._ordersValue) &&
            this._ordersValue !== this.options.ordersValue
        ) {
            await onSaveOrders(this._ordersValue);
        }
        this._log.log('save complete');
    };

    registerPageController = pc => {
        this._log.log('registerPageController');
        this._pageControllers[pc.pageNumber] = pc;
    };

    handleClickNext(current) {
        current ||= this.current;
        const pageControllers = fp.pipe([fp.values, fp.sortBy('pageNumber')])(
            this._pageControllers,
        );

        const next = lib.nextUnfilledElement(current, pageControllers);
        if (!next) {
            return;
        }
        const nextPageController = pageControllers[next.pageNumber];
        if (!nextPageController) return;
        for (var i = 0; i < pageControllers.length; i++) {
            if (i !== next.pageNumber) {
                pageControllers[i].clearActiveObjects();
            }
        }
        nextPageController.canvas.setActiveObject(next.element);
        nextPageController.canvas.requestRenderAll();
        this.setCurrent(next.pageNumber, next.element);
    }

    setCurrent(pageNumber, element) {
        if (!element) {
            this.options.setProgressState?.(null);
            return;
        }
        this.current = {pageNumber, element};
        const label = element.field.progressLabel;
        let offset = 0;
        for (var pg = 0; pg < pageNumber; pg++) {
            offset += this._pageControllers[pg].canvasHeight;
        }
        const top =
            offset + this._pageControllers[pageNumber].scale(element.top);
        this.options.setProgressState?.({
            style: {top},
            label,
        });
    }
}
