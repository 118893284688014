import {useMemo} from 'react';

import {FormController, PageController} from './controllers';

export function useFormController({
    form,
    ...options
    /*
    user,
    signatureBlob,
    ordersValue,
    onSavePage,
    onEditOrders,
    onSaveOrders,
    setProgressState,
    setNumIncomplete,
    */
}) {
    const formId = form?.id;
    const formController = useMemo(() => {
        const controller = new FormController(formId);
        return controller;
    }, [formId]);
    formController.form = form;
    formController.options = options;
    return formController;
}

export function usePageController({
    page,
    pageNumber,
    formController,
    ...options
}) {
    const pageController = useMemo(() => {
        const controller = new PageController(pageNumber);
        return controller;
    }, [pageNumber]);
    pageController.page = page;
    pageController.formController = formController;
    pageController.options = options;
    return pageController;
}
