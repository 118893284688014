import React from 'react';
import {makeStyles} from '@material-ui/core';

import ToolsDrawer from './ToolsDrawer';
import SigningProgress from './SigningProgress';
import SignaturePage from './SignaturePage';

import {useFormPages} from './hooks';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    leftGutterTools: {
        flex: '0 0 auto',
        width: theme.spacing(20),
    },
    leftGutterProgress: {
        flex: '0 0 auto',
        width: theme.spacing(14),
        height: '100%',
    },
    scrollableContent: {
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    contentNoScroll: {
        flex: 1,
    },
}));

export default function SignatureForm({
    formController,
    progressState,
    isEditing,
}) {
    const classes = useStyles();
    const pages = useFormPages(formController.form);

    return (
        <div className={classes.container}>
            {isEditing && (
                <div className={classes.leftGutterTools}>
                    <ToolsDrawer />
                </div>
            )}
            <div className={classes.scrollableContent}>
                {!isEditing && (
                    <div className={classes.leftGutterProgress}>
                        <SigningProgress
                            formController={formController}
                            progressState={progressState}
                        />
                    </div>
                )}
                <div className={classes.contentNoScroll}>
                    {pages.map((page, pageNumber) => (
                        <SignaturePage
                            key={page.id}
                            formController={formController}
                            page={page}
                            pageNumber={pageNumber}
                            isEditing={isEditing}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
