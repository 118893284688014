import fp from 'lodash/fp';
import * as dfp from 'date-fns/fp';
import {fabric} from 'fabric';

import {Field} from './Field';

const formatDate = dfp.format('P');

export class DateField extends Field {
    static imgSrc = '/img/dateBox.png';

    get progressLabel() {
        if (this.filled) {
            return 'Next';
        } else {
            return 'Enter date';
        }
    }

    toJSON(el) {
        let result = super.toJSON(el);
        const elProps = fp.pick(
            ['top', 'left', 'scaleX', 'scaleY', 'angle'],
            el,
        );
        result.field_data = {
            elProps,
            oldElProps: el.oldElProps || elProps,
        };
        return result;
    }

    draw() {
        return new Promise(resolve => {
            if (this.filled) {
                var el = new fabric.Textbox(
                    this.data.value || 'NO DATE ENTERED',
                    {fontSize: 40, editable: false},
                );
                el.setControlsVisibility({
                    mt: false,
                    mb: false,
                    ml: false,
                    mr: false,
                });
                if (fp.isEmpty(this.data.field_data)) {
                    this.set(el, {
                        backgroundColor: undefined,
                        left: this.data.coords.left,
                        top: this.data.coords.top,
                    });
                } else {
                    this.set(el, {
                        ...this.data.field_data.elProps,
                        backgroundColor: undefined,
                    });
                }
                resolve(el);
            } else {
                fabric.Image.fromURL(
                    DateField.imgSrc,
                    el => {
                        el.setControlsVisibility({
                            mt: false,
                            mb: false,
                            ml: false,
                            mr: false,
                        });
                        if (fp.isEmpty(this.data.field_data)) {
                            this.set(el, {
                                backgroundColor: Field.UNFILLED_BACKGROUND,
                                left: this.data.coords.left,
                                top: this.data.coords.top,
                            });
                        } else {
                            this.set(el, {
                                ...this.data.field_data.elProps,
                                backgroundColor: Field.UNFILLED_BACKGROUND,
                            });
                        }
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            }
        });
    }

    async click(ev, el, canvas) {
        if (this.filled) {
            this.filled = false;
            this.data.value = null;
        } else {
            this.filled = true;
            this.data.value = formatDate(new Date());
        }
        const newEl = await this.draw();
        newEl.oldElProps = fp.pick(
            ['top', 'left', 'scaleX', 'scaleY', 'angle'],
            el,
        );
        newEl.set(fp.pick(['top', 'left', 'angle'], el));
        this.scaleElement(el, newEl, {useMax: !this.filled});
        canvas.add(newEl);
        canvas.remove(el);
        return this._postClick(el, newEl, canvas);
    }
}
