import React from 'react';
import {Tooltip, IconButton, Icon} from '@material-ui/core';
import {
    BorderColor,
    Brush,
    Check,
    CheckBox,
    Crop,
    CropPortrait,
    Delete,
    RotateRight,
    RotateLeft,
    TextFields,
    Today,
    Save,
} from '@material-ui/icons';

import {useEditorContext} from './context';
import {
    trash,
    rotate,
    startCrop,
    completeCrop,
    save,
    toggleClickMode,
    toggleDrawingMode,
    toggleErasingMode,
} from './actions';

function EditorToolBtn({
    title,
    color,
    onClick,
    icon,
    iconStyle,
    disabled,
    variant = 'contained',
}) {
    return (
        <Tooltip title={title}>
            <span>
                <IconButton
                    style={{backgroundColor: `rgba(255, 255, 255, 0.5)`}}
                    disabled={disabled}
                    color={color}
                    onClick={onClick}
                    variant={variant}
                >
                    <Icon style={iconStyle}>{icon}</Icon>
                </IconButton>
            </span>
        </Tooltip>
    );
}

export function RotateLeftButton() {
    const ctx = useEditorContext();
    return (
        <EditorToolBtn
            title='Rotate -90º'
            color='primary'
            icon={<RotateLeft />}
            onClick={rotate(ctx, -90)}
        />
    );
}

export function RotateRightButton() {
    const ctx = useEditorContext();
    return (
        <EditorToolBtn
            title='Rotate +90º'
            color='primary'
            icon={<RotateRight />}
            onClick={rotate(ctx, +90)}
        />
    );
}

export function CropButton() {
    const ctx = useEditorContext();
    return ctx.isCropping ? (
        <EditorToolBtn
            title='Apply crop'
            color='secondary'
            onClick={completeCrop(ctx)}
            icon={<CheckBox />}
        />
    ) : (
        <EditorToolBtn
            title='Crop image'
            color='primary'
            onClick={startCrop(ctx)}
            icon={<Crop />}
        />
    );
}

export function SaveButton({onSave, ...props}) {
    const ctx = useEditorContext();
    return (
        <EditorToolBtn
            title='Save and close Image'
            color='primary'
            icon={<Save />}
            onClick={save(ctx, onSave)}
            {...props}
        />
    );
}

export function TrashButton({...props}) {
    const ctx = useEditorContext();
    return (
        <EditorToolBtn
            title='Delete selected item(s)'
            color='primary'
            icon={<Delete />}
            onClick={trash(ctx)}
        />
    );
}

export function BrushButton({...props}) {
    const ctx = useEditorContext();
    const {clickMode} = ctx;
    const isEnabled = 'drawing' === clickMode?.mode;
    const color = isEnabled ? 'secondary' : 'primary';
    return (
        <EditorToolBtn
            title='Draw'
            color={color}
            icon={<Brush />}
            onClick={toggleDrawingMode(ctx)}
        />
    );
}

function ModeButton({mode, ...props}) {
    const ctx = useEditorContext();
    const {clickMode} = ctx;
    const isEnabled = mode === clickMode?.mode;
    const color = isEnabled ? 'secondary' : 'primary';

    return (
        <EditorToolBtn
            color={color}
            onClick={toggleClickMode(ctx, mode)}
            {...props}
        />
    );
}

export function EraserButton({...props}) {
    const ctx = useEditorContext();
    const {clickMode} = ctx;
    const isEnabled = 'erasing' === clickMode?.mode;
    const color = isEnabled ? 'secondary' : 'primary';
    return (
        <EditorToolBtn
            title='Erase'
            color={color}
            icon={<CropPortrait />}
            iconStyle={{rotate: '45deg'}}
            onClick={toggleErasingMode(ctx)}
        />
    );
}

export function TextButton({...props}) {
    return <ModeButton mode='texting' title='Text' icon={<TextFields />} />;
}

export function DateButton({...props}) {
    return <ModeButton mode='dating' title='Date stamp' icon={<Today />} />;
}

export function CheckButton({...props}) {
    return <ModeButton mode='checking' title='Check mark' icon={<Check />} />;
}

export function SignatureButton({...props}) {
    return (
        <ModeButton mode='signing' title='Signature' icon={<BorderColor />} />
    );
}
