import React from 'react';
import {useSelector} from 'react-redux';

import {ducks} from '@arborian/narrf';

import TagChip from 'ccm/components/PracticeManagement/tags/TagChip';

export default function PatientTags({patient}) {
    const relPat = useSelector(ducks.jsonapi.selectRelated(patient));
    const facility = relPat.facility;
    const relFac = useSelector(ducks.jsonapi.selectRelated(facility));
    const patientTags = relPat?.tags || [];
    const facilityTags = relFac?.tags || [];
    const tags = [...patientTags, ...facilityTags];
    return (
        <div>
            {tags.map(tag => (
                <TagChip
                    key={tag.id}
                    tagAttrs={tag?.attributes}
                    size='small'
                    variant='outlined'
                />
            ))}
        </div>
    );
}
