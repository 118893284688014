export class Mutex {
    // Thanks to https://dev.to/0916dhkim/simple-typescript-mutex-implementation-5544
    constructor() {
        this._queue = [];
        this._isLocked = false;
    }

    acquire() {
        return new Promise(resolve => {
            this._queue.push({resolve});
            this._dispatch();
        });
    }

    async runExclusive(callback) {
        const release = await this.acquire();
        try {
            return await callback();
        } finally {
            release();
        }
    }

    _dispatch() {
        if (this._isLocked) return;
        const nextEntry = this._queue.shift();
        if (!nextEntry) return;
        this._isLocked = true;
        nextEntry.resolve(() => {
            this._isLocked = false;
            this._dispatch();
        });
    }
}

export class NonceLog {
    static _next = {};
    static nonce(name) {
        const result = NonceLog._next[name] || 1;
        NonceLog._next[name] = result + 1;
        return result;
    }

    constructor(name) {
        const instance = NonceLog.nonce(name);
        this._prefix = `${name}[${instance}]`;
    }

    sub(name) {
        return new NonceLog(`${this._prefix}.${name}`);
    }

    log = (...args) => {
        return console.log(this._prefix, ...args);
    };
}

export const setCanvasBackgroundImage = (canvas, src) =>
    new Promise((resolve, reject) => {
        canvas.setBackgroundImage(
            src,
            (img, isError) => (isError ? reject(img) : resolve(img)),
            {crossOrigin: 'Anonymous'},
        );
    });

export const scaleCanvasToImage = (canvas, img) => {
    const imgWidth = img.width;
    const imgHeight = img.height;
    const canvasWidth = canvas.getWidth();
    if (imgWidth > canvasWidth) {
        let zoomOutValue = canvasWidth / imgWidth;
        canvas.setZoom(zoomOutValue);
    }
    canvas.setHeight(canvas.getZoom() * imgHeight);
    canvas.calcOffset();
};

export function canvasToFullsizeBlob(canvas) {
    canvas.setWidth(canvas.backgroundImage.width);
    canvas.setHeight(canvas.backgroundImage.height);
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    canvas.calcOffset();
    canvas.discardActiveObject();
    canvas.renderAll();
    return new Promise(resolve => {
        canvas.toCanvasElement().toBlob(resolve, 'image/png');
    });
}

export function nextUnfilledElement(current, pageControllers) {
    let foundCurrent = current?.element ? false : true;
    for (let i = 0; i < 2; i++) {
        for (const pc of pageControllers) {
            const pageObjects = pc.getSortedObjects();
            for (var obj of pageObjects) {
                if (foundCurrent) {
                    if (!obj.field.data.filled) {
                        return {
                            pageNumber: pc.pageNumber,
                            element: obj,
                        };
                    }
                } else if (
                    current.pageNumber === pc.pageNumber &&
                    current.element === obj
                ) {
                    foundCurrent = true;
                }
            }
        }
    }
    return null;
}
