import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {useApi, ducks} from '@arborian/narrf';

import BasePage from 'ccm/components/BasePage';
import Ticket from 'ccm/components/ticket';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import useWebsocket from 'ccm/lib/websocket';
import Chat from 'ccm/components/Chat';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        marginLeft: 'auto',
        marginRight: 'auto',
        flex: '1 0 auto',
        width: '100%',
    },
    wider: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    alignRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    responsiveHide: {
        [theme.breakpoints.up('lg')]: {},
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
const include = [
    'patient',
    'patient.tags',
    'patient.ehr_connection',
    'facility',
    'facility.tags',
    'practice',
    'practice.contacts',
    'practice.ehr_connection',
    'facility.contacts',
    'narrative',
    'originalTicket',
    'followUpTickets',
    'signature_form',
    'signature_form.pages',
];

const initializePage = async (api, ticket_id, setPageReady) => {
    const promises = [
        api.fetchJsonApi(api.url_for('ticket.Ticket', {ticket_id}), {include}),
    ];
    await Promise.all(promises);
    setPageReady(true);
};

const wsOptions = {include};
export default function TicketPage({match, classes}) {
    classes = {...useStyles(), classes};
    const api = useApi();
    const ticketid = match.params.ticketid;
    useWebsocket(`/ticket/${ticketid}/chat`, wsOptions);

    const [pageReady, setPageReady] = useState(false);
    const [chatOpen, setChatOpen] = useState(null);

    const {enqueueSnackbar} = useSnackbar();

    const user = useSelector(selectCurrentUser);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const ticket = useSelector(
        ducks.jsonapi.selectObject(['Ticket', ticketid]),
    );

    const ticketPracticeId = fp.get('relationships.practice.data.id', ticket);
    const hasChatPermission =
        fp.includes('__admin__', userinfo?.scopes) ||
        !!fp.pipe([
            fp.get('relationships.groups.data'),
            fp.filter(g => g.id.includes(`practice:${ticketPracticeId}`)),
            fp.size,
        ])(user);

    const hasChatMessages = !fp.isEmpty(fp.get('attributes.data.chat', ticket));
    useEffect(() => {
        if (chatOpen === null && ticket) {
            setChatOpen(hasChatMessages);
        }
    }, [setChatOpen, hasChatMessages, chatOpen, ticket]);

    const newChatMessage = async message => {
        await api.fetchJsonApi(ticket.links.chat, {
            method: 'POST',
            json: {message},
        });
    };
    const chatProps = {
        open: chatOpen,
        setOpen: setChatOpen,
        handleNewMessage: newChatMessage,
        messages: fp.get('attributes.data.chat', ticket),
        hasChatPermission,
        hasMessages: hasChatMessages,
        user,
    };

    const patchTicket = useCallback(
        async json => {
            let {type, id} = ticket;
            let attributes = fp.merge(ticket.attributes, json.data.attributes);
            await api.fetchJsonApi(ticket.links.self, {
                method: 'PATCH',
                include,
                json: {data: {type, id, attributes}},
            });
            enqueueSnackbar('Ticket Updated', {
                persist: false,
                variant: 'success',
            });
        },
        [api, ticket, enqueueSnackbar],
    );

    useEffect(() => {
        setPageReady(false);
        initializePage(api, ticketid, setPageReady);
    }, [api, ticketid, setPageReady]);

    if (!ticket || !pageReady) return null;
    return (
        <BasePage title={`Ticket ${fp.get('id', ticket)}`}>
            <div className={classes.alignRow}>
                <div className={classes.root}>
                    <Ticket
                        ticket={ticket}
                        include={include}
                        onChanged={patchTicket}
                        chatProps={chatProps}
                    />
                </div>
                {chatProps.hasChatPermission && (
                    <Chat sidebar={true} {...chatProps} />
                )}
            </div>
        </BasePage>
    );
}
