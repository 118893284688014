import {Field} from './Field';
import {SignatureField} from './SignatureField';
import {DateField} from './DateField';
import {TextboxField} from './TextboxField';
import {OrdersField} from './OrdersField';
import {CheckboxField} from './CheckboxField';

Field.TOOLS = {
    SIGNATURE: SignatureField,
    DATE: DateField,
    TEXTBOX: TextboxField,
    ORDERSBOX: OrdersField,
    CHECKBOX: CheckboxField,
};

export {Field};
