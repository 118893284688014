import fp from 'lodash/fp';
import {fabric} from 'fabric';

import {Field} from './Field';

export class SignatureField extends Field {
    static imgSrc = '/img/signatureBox.png';
    static initialProps = {
        width: 350,
        height: 150,
        scaleX: 1,
        scaleY: 1,
    };

    get progressLabel() {
        if (this.filled) {
            return 'Next';
        } else {
            return 'Sign';
        }
    }

    toJSON(el) {
        let result = super.toJSON(el);
        result.field_data = {
            elProps: fp.pick(['top', 'left', 'scaleX', 'scaleY', 'angle'], el),
            oldElProps: el.oldElProps || {},
        };
        return result;
    }

    draw() {
        return new Promise(resolve => {
            if (this.filled) {
                var url, eSignMessage;
                try {
                    const jsonValue = JSON.parse(this.data.value);
                    url = jsonValue.url;
                    eSignMessage = `Electronically Signed by ${jsonValue.name}\n${jsonValue.timestamp}`;
                } catch (err) {
                    // For backwards compatibility with CCM
                    url = this.data.value;
                    eSignMessage = '';
                    console.log('Error parsing signature data', err);
                }
                fabric.Image.fromURL(
                    url,
                    signatureStamp => {
                        const bottom =
                            signatureStamp.top +
                            signatureStamp.getScaledHeight();
                        const eSignStamp = new fabric.Textbox(eSignMessage, {
                            field: this,
                            width: signatureStamp.width,
                            top: bottom,
                            left: signatureStamp.left,
                        });
                        const group = new fabric.Group(
                            [signatureStamp, eSignStamp],
                            {field: this},
                        );
                        if (fp.isEmpty(this.data.field_data)) {
                            console.log('Setting group props', {group});
                            this.set(group, {
                                left: this.data.coords.left,
                                top: this.data.coords.top,
                                scaleX: this.data.coords.width / group.width,
                                scaleY: this.data.coords.height / group.height,
                            });
                        } else {
                            this.set(group, this.data.field_data.elProps);
                        }
                        group.setControlsVisibility({
                            mt: false,
                            mb: false,
                            ml: false,
                            mr: false,
                        });
                        resolve(group);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            } else {
                fabric.Image.fromURL(
                    SignatureField.imgSrc,
                    el => {
                        el.setControlsVisibility({
                            mt: false,
                            mb: false,
                            ml: false,
                            mr: false,
                        });
                        // debugger;
                        if (fp.isEmpty(this.data.field_data)) {
                            // Backwards compatibility
                            this.set(el, {
                                backgroundColor: Field.UNFILLED_BACKGROUND,
                                left: this.data.coords.left,
                                top: this.data.coords.top,
                                scaleX: this.data.coords.width / el.width,
                                scaleY: this.data.coords.height / el.height,
                            });
                            // this.scaleElement(el);
                            // this.pageController.canvas.add(el);
                        } else {
                            this.set(el, {
                                backgroundColor: Field.UNFILLED_BACKGROUND,
                                ...this.data.field_data.elProps,
                            });
                        }
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            }
        });
    }

    click = async (ev, el, canvas) => {
        let newEl = null;
        if (this.filled) {
            // "Unsign"
            this.filled = false;
            this.data.value = null;
        } else {
            this.filled = true;
            this.data.value = this.pageController.signatureValue;
        }
        newEl = await this.draw();
        newEl.oldElProps = fp.pick(
            ['top', 'left', 'scaleX', 'scaleY', 'angle'],
            el,
        );
        newEl.set(fp.pick(['top', 'left', 'angle'], el));
        this.scaleElement(el, newEl, {useMax: this.filled});
        canvas.add(newEl);
        canvas.remove(el);
        return this._postClick(ev, newEl, canvas);
    };
}
